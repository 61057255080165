import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Modal, Spin } from "antd";
import { useDispatch, useSelector } from 'react-redux';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Edit } from "@mui/icons-material";

import Drawer from '../../../components/Drawer';
import ButtonSt from '../../../components/ButtonSt';
import AgGrid from '../../../componentsv2/AgGridTable';
import Button from '../../../componentsv2/Button';
import Input from '../../../components/Inputs/label-input';
import InventoryOwnership from './inventoryOwnership';
import Select from '../../../components/Select';
import Notification from '../../../components/notifications/notification';
import ProofOfDelivery from './proofOfDelivery';
import ChatBox from './chatBox';
import LeftArrow from '../../../assets/icons/arrow-left-short.svg';
import LeftArrowSp from '../../../assets/icons/arrow-left-short-sp.svg';
import DownArrow from '../../../assets/icons/arrow-down-white.svg';
import UpArrow from '../../../assets/icons/arrow-up-primary.svg';
import saveIcon from '../../../assets/icons/disk-save.svg';
import chatIcon from '../../../assets/icons/chat-icon.svg';
import clientStar from '../../../assets/icons/client-star.svg';

import { UploadDocumentsWrapper } from '../style';

import { FormatValueToLocaleString, shipmentStatusMapping } from '../../../helpers/format-value';
import { APP_DOMAINS } from '../../../constants';

import {
  GetCaseChat,
  GetFbaShipmentDocuments,
  GetFbaShipmentsItems,
  SaveCaseId,
  SaveNotes,
  SetShipmentState,
  SubmitDocuments,
  UpdateShipment
} from '../../../redux/slices/shipment-slice';

const shipmentDetails = [
  {
    label: 'Name',
    data: 'shipmentName'
  },
  {
    label: 'ID',
    data: 'shipmentConfirmationId'
  },
  {
    label: 'Expires In',
    data: 'shipmentExpirationDate'
  },
  {
    label: 'Type',
    data: 'type'
  },
  {
    label: 'Discrepancy',
    data: 'discrepancy'
  },
  {
    label: 'Potential Recovery',
    data: 'potentialRecovery'
  },
  {
    label: 'Case ID',
    data: 'caseId'
  },
  {
    label: 'Case Date',
    data: 'caseDate'
  }
]

const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    {props.data}
  </Tooltip>
);

const statusList = [{
  label: 'Awaiting Document',
  value: 'Awaiting Document'
}, {
  label: 'Reimbursed',
  value: 'Reimbursed'
}, {
  label: 'Declined',
  value: 'Declined'
}, {
  label: 'Additional Information Required',
  value: 'Additional Information Required'
}, {
  label: `Pending Action From ${window.location.hostname === APP_DOMAINS.sellerRepay ? 'Seller Repay' : 'Seller Terminal'}`,
  value: 'Pending Action From Seller Terminal'
}, {
  label: 'Pending Action From Amazon',
  value: 'Pending Action From Amazon'
}]

const columnDefs = [
  {
    headerName: 'SKU',
    minWidth: 140,
    flex: 1,
    field: '_id',
  },
  {
    headerName: 'FNSKU',
    minWidth: 140,
    flex: 1,
    field: 'fnsku',
    cellRendererFramework: ({ data }) => {
      return (
        <span >{data.fnsku || 'N/A'}</span>
      );
    }
  },
  {
    headerName: 'Qty Shipped',
    minWidth: 140,
    flex: 1,
    field: 'quantityShipped',
  },
  {
    headerName: 'Qty Received',
    minWidth: 140,
    flex: 1,
    field: 'quantityReceived'
  },
  {
    headerName: 'Discrepancy',
    minWidth: 140,
    flex: 1,
    cellRendererFramework: ({ data }) => {
      return (
        <span className='font-weight-bold'>{data.discrepancy || 'N/A'}</span>
      );
    }
  },
  {
    headerName: 'Potential Recovery',
    minWidth: 140,
    flex: 1,
    cellRendererFramework: ({ data }) => {
      return (
        <span className='font-weight-bold'>{data.potentialRecovery ? FormatValueToLocaleString(data.potentialRecovery) : '-'}</span>
      );
    }
  },
]

const UploadDocuments = ({ openDrawer, setOpenDrawer, data }) => {
  const dispatch = useDispatch();
  const [rowData, setRowData] = useState([]);
  const [back, setBack] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [caseId, setCaseId] = useState('');
  const [clientNotes, setClientNotes] = useState('');
  const [openChat, setOpenChat] = useState(false);
  const [cssClass, setCssClass] = useState('');
  const [changeStatus, setChangeStatus] = useState(false);
  const [updatedStatus, setUpdatedStatus] = useState('');
  const buttonRef = useRef(null);

  const {
    shipmentItems,
    shipmentItemsTotal,
    loading,
    shipmentDocuments,
    uploadedDocuments,
    message,
    error,
    success
  } = useSelector((state) => state.shipment);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(GetFbaShipmentDocuments({ shipmentId: data.shipmentObjectId }));
  }, []);

  useEffect(() => {
    let cssClass = 'text-secondary';

    if (['Awaiting Document', 'Reimbursable'].includes(data.status)) {
      cssClass = 'status warning-badge';
    } else if ([
      'Pending Action From Seller Terminal',
      'Pending Action From Amazon',
      'Additional Information Required'
    ].includes(data.status)) {
      cssClass = 'status info-badge';
    } else if (data.status === 'Reimbursed') {
      cssClass = 'status success-badge';
    } else if (data.status === 'Declined') {
      cssClass = 'status error-badge';
    }

    setCssClass(cssClass);
  }, [data])

  useEffect(() => {
    if (shipmentItems.length) {
      setRowData(shipmentItems);
    }
  }, [shipmentItems])

  const handleDocumentSubmit = () => {
    if (uploadedDocuments.length) {
      dispatch(SubmitDocuments({ files: uploadedDocuments, shipmentId: data.shipmentObjectId, storeId: data.storeId }))
    } else {
      Notification({
        type: 'error',
        title: 'No Documents Uploaded',
        description: 'You have not uploaded any documents. Please upload documents then click Document Submitted button',
        duration: 6
      });
    }
  }

  const handleSaveNotes = () => {
    if (clientNotes) {
      dispatch(SaveNotes({ notes: clientNotes, shipmentId: data.shipmentObjectId }))
    } else {
      Notification({
        type: 'error',
        title: 'Notes are empty!',
      });
    }
  }

  const handleSaveCaseId = () => {
    if (caseId) {
      dispatch(SaveCaseId({ caseId, shipmentId: data.shipmentObjectId }))
    } else {
      Notification({
        type: 'error',
        title: 'Case Id is empty!',
      });
    }
  }

  const handleLeaving = () => {
    if (!uploadedDocuments.length || back) {
      setOpenDrawer(false)
    } else {
      Notification({
        type: 'error',
        title: 'Documents Not Submitted',
        description: 'You have unsubmitted documents. Please submit the documents by clicking "Document Submitted" button or press back button again to leave this page.',
        duration: 0
      });
      setBack(true);
      if (buttonRef.current) {
        buttonRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        const btn = document.getElementsByName('submitDocumentBtn')[0];
        const prevStyle = btn.style;
        document.getElementsByName('submitDocumentBtn')[0].style.border = '2px solid red'
        setTimeout(() => {
          document.getElementsByName('submitDocumentBtn')[0].style = prevStyle
        }, 1000);
      }
    }
  }

  const handleChat = () => {
    setOpenChat(true);
    const {
      storeId,
      shipmentConfirmationId,
      caseId
    } = data;
    dispatch(GetCaseChat({
      storeId,
      shipmentConfirmationId,
      caseId
    }))
  }

  useEffect(() => {
    if (uploadedDocuments.length && message === 'Documents Submitted Successfully') {
      dispatch(SetShipmentState({ field: 'message', value: '' }));
      dispatch(SetShipmentState({ field: 'uploadedDocuments', value: [] }));
      setOpenDrawer(false);
      Notification({
        type: 'success',
        title: 'Documents Submitted Successfully',
      });
    } else if (success && ['Case Submitted Successfully', 'Shipment Updated Successfully'].includes(message)) {
      let title = 'Case Submitted Successfully';
      if (message.includes('Updated')) title = 'Status Updated Successfully';
      dispatch(SetShipmentState({ field: 'message', value: '' }));
      dispatch(SetShipmentState({ field: 'success', value: false }));
      setOpenDrawer(false);
      Notification({
        type: 'success',
        title
      });
    }
  }, [uploadedDocuments, message])

  return (
    <>
      <Drawer show={openDrawer} size='1020px'>
        <UploadDocumentsWrapper>
          {(loading && !showMore) &&
            <div className="d-flex position-absolute justify-content-center align-items-center loader-wrapper">
              <Spin size="large" />
            </div>
          }
          <div className='drawer-main'>
            <div className='drawer-heading d-flex align-items-center justify-content-between'>
              <div className='d-flex align-items-center'>
                <img src={window.location.hostname === APP_DOMAINS.sellerTerminal ? LeftArrow : LeftArrowSp} alt='arrow' onClick={handleLeaving} className='mr-2 cursor-pointer' />
                <h6>Upload Document</h6>
              </div>
              <div className='d-flex align-items-center document-status'>
                <p>Status:</p>
                <div className="d-flex">
                  <div className={cssClass}>
                    {shipmentStatusMapping(data.status)}
                  </div>
                  {
                    user?.role === 'admin' ? (
                      <Edit 
                        style={{ 
                          // height: '15px',
                          cursor: 'pointer',
                          margin: '3px 0px 0px 5px'
                        }}
                        onClick={() => setChangeStatus(true)}
                        color='disabled'
                        fontSize="small"
                      />
                    ) : null
                  }
                </div>
                <div className='d-flex align-items-center'>
                  {data?.caseId || data?.clientNotes ?
                    <div className='view-chat-btn'>
                      <Button
                        text='View Chat / Conversation'
                        className='primary'
                        startIcon={
                          <img src={chatIcon} alt='chat'
                            className='mr-2' />
                        }
                        onClick={handleChat}
                      />
                    </div>
                    : ''
                  }
                </div>
              </div>
            </div>
            {shipmentDocuments?.length ?
              <>
                {data?.clientNotes ?
                  <div className='d-flex align-items-start client-notes'>
                    <img src={clientStar} alt='clientStar' />
                    <div>
                      <p>Client Notes</p>
                      <span>{data?.clientNotes}</span>
                    </div>
                  </div>
                  : user?.role === 'admin' ? (
                    <div className='case-box'>
                      {data?.caseId ?
                        <div className='d-flex align-items-start client-input'>
                          <Input onChange={(e) => setClientNotes(e.target.value)} placeholder='Enter Client Notes' label='Client Notes' as='textarea' rows={3} />
                          <Button icon={<img src={saveIcon} alt='save' />} className='primary' onClick={handleSaveNotes} />
                        </div>
                        :
                        <div className='d-flex align-items-end case-input'>
                          <Input onChange={(e) => setCaseId(e.target.value)} placeholder='Enter Case ID' label='Case ID' />
                          <Button icon={<img src={saveIcon} alt='save' />} className='primary' onClick={handleSaveCaseId} />
                        </div>
                      }
                    </div>
                  ) : null
                }
              </> : null
            }
            <div className='position-relative'>
              <div className={`document-details ${showMore ? 'full-height' : 'less-height'}`}>
                <div className='d-flex align-items-start gap-32'>
                  <div>
                    <p>Store:</p>
                    <div>
                      <label>{data?.storeName || ''}</label>
                      <span>{data?.userEmail || ''}</span>
                    </div>
                  </div>
                  <div>
                    <p>Shipment:</p>
                    <div className='d-flex align-items-start shipment-data'>
                      {shipmentDetails.map((item, ind) => (
                        (item.label !== 'Case ID' && item.label !== 'Case Date') ? (
                          <div key={ind}>
                            <label>{item.label}</label>
                            {item.label === 'Name' ? (
                              <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip({ data: data[item.data] || 'N/A' })}
                              >
                                <span>{data[item.data] || 'N/A'}</span>
                              </OverlayTrigger>
                            ) : (
                              item.label === 'Expires In' ? (
                                  <span>{data[item.data] !== null ? (data[item.data] > 0 ? data[item.data] + ' days' : data.shipmentExpirationDate === 0 ? 'Today' : 'Expired') : 'N/A'}</span>
                              ) : (
                                <span>{data[item.data] || 'N/A'}</span>
                              )
                            )}
                          </div>
                        ) : (
                          data[item.data] ? (
                            <div key={ind}>
                              <label>{item.label}</label>
                              {
                                  item.label === 'Case Date' ? (
                                    <span>{moment(data[item.data]).format('L') || 'N/A'}</span>
                                  ): (
                                      <span>{data[item.data] || 'N/A'}</span>
                                  )
                              }
                            </div>
                          ) : null
                        )
                      ))}
                    </div>

                  </div>
                </div>
                <hr className='shipment-hr' />
                {
                  !loading ? (
                    <AgGrid
                      rowData={rowData}
                      columnDefs={columnDefs}
                      rowHeight={42}
                      height="580px"
                    />
                  ) : (
                    <div className='d-flex justify-content-center align-content-center'>
                      <Spin size='small' />
                    </div>
                  )
                }
              </div>
              <div className='show-more-btn'>
                {showMore ?
                  <Button text='Show Less' className='outlined' icon={<img src={UpArrow} alt='arrow' className='ml-1' />} onClick={() => setShowMore(false)} />
                  :
                  <Button
                    text='Show More'
                    className='primary'
                    icon={<img src={DownArrow} alt='arrow' className='ml-1' />}
                    onClick={() => {
                      dispatch(GetFbaShipmentsItems({ storeId: data.storeId, shipmentId: data.shipmentObjectId }))
                      setShowMore(true);
                    }}
                  />
                }
              </div>
            </div>
            <InventoryOwnership uploaded={uploadedDocuments} shipmentId={data.shipmentObjectId} files={shipmentDocuments} />
            <ProofOfDelivery uploaded={uploadedDocuments} shipmentId={data.shipmentObjectId} files={shipmentDocuments} />
            <div ref={buttonRef} className='submitted-btn'>
              <Button name='submitDocumentBtn' text='Submit Documents' onClick={handleDocumentSubmit} />
            </div>
          </div>
        </UploadDocumentsWrapper>
      </Drawer>
      {
        openChat ? (
          <ChatBox openChat={openChat} setOpenChat={setOpenChat} />
        ) : null
      }
      {
        changeStatus ? (
          <Modal
            footer={null}
            centered
            onOk={() => setPaymentLater(false)}
            closeIcon={false}
            closable={false}
            okText="Proceed"
            open={changeStatus}
            className="processed-payment-modal"
          >
            <div className="box-wrapper">
              <h6>Update Shipment Status</h6>
              <p>Please select the status from the dropdown below:</p>{" "}
              <Select
                options={statusList}
                handleChange={(e) => setUpdatedStatus(e.value || '')}
              />
              <div className="d-flex justify-content-center">
                <ButtonSt
                  className='mx-2'
                  outline
                  onClick={() => setChangeStatus(false)}
                >
                  Cancel
                </ButtonSt>
                <ButtonSt
                  onClick={() => {
                    if (updatedStatus) {
                      dispatch(UpdateShipment({ shipmentId: data.shipmentObjectId, shipmentData: { status: updatedStatus} }))
                    } else {
                      Notification({
                        type: 'error',
                        title: 'No Status Selected',
                        description: 'Please Select Status Then Click The Update Button'
                      });
                    }
                  }}
                >
                  Update
                </ButtonSt>
              </div>
            </div>
          </Modal>
        ) : null
      }
    </>
  )
}

export default UploadDocuments;
