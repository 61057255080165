import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";

import { axiosBaseUrl } from '../../config/axios-configuration';

import SaveSentryLog from "../../helpers/sentry-log";

const axios = axiosBaseUrl();

export const GetInvoiceSummary = createAsyncThunk(
  'invoice/getInvoiceSummary',
  async (data, { rejectWithValue }) => {
    try {
      const {
        filters,
        skip,
        limit
      } = data;
      const response = await axios.get('/invoice/get-invoice-summary', {
        params: {
          filters,
          skip,
          limit
        }
      });
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'GetInvoiceSummaryError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const GetInvoiceDetails = createAsyncThunk(
  'invoice/getInvoiceDetails',
  async (data, { rejectWithValue }) => {
    try {
      const {
        invoiceId,
        skip,
        limit
      } = data;

      const response = await axios.get('/invoice/get-invoice-details', {
        params: {
          invoiceId,
          skip,
          limit
        }
      });
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'GetInvoiceDetailsError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const GetCaseStatDetail = createAsyncThunk(
  'invoice/getCaseStatDetail',
  async (data, { rejectWithValue }) => {
    try {
      const {
        caseId,
        skip,
        limit,
        type
      } = data;
      const response = await axios.get('/invoice/get-case-stat-detail', {
        params: {
          type,
          caseId,
          skip,
          limit
        }
      });
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'GetCaseStatDetailError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const DownloadInvoicePdf = createAsyncThunk(
  'invoice/downloadInvoicePdf',
  async (data, { rejectWithValue }) => {
    try {
      const { invoiceNumber } = data;
      const params = { invoiceNumber };
      const getQueryString = (p) => {
        const esc = encodeURIComponent;
        return Object.keys(p)
          .map(k => `${esc(k)}=${esc(params[k])}`)
          .join('&');
      };

      const url = `${process.env.API_URL}/auth/download-invoice-pdf?${getQueryString(params)}`;
      setTimeout(() => {
        window.open(url, '_blank');
      }, 0);
    } catch (err) {
      SaveSentryLog(new Error(err), 'DownloadInvoicePdfError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const PayInvoice = createAsyncThunk(
  'invoice/payInvoice',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/invoice/pay-invoice', data);
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'PayInvoiceError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

const invoice = createSlice({
  name: 'invoice',
  initialState: {
    error: '',
    loading: '',
    message: '',
    success: false,
    total: 0,
    invoicesSummary: [],
    invoiceDetails: [],
    invoiceDetailsTotal: 0,
    headerDetailOfInvoiceDetail: {},
    totalReimbursments: {},
    caseStatDetails: [],
    caseStatDetailsTotal: 0,
    invoiceSummarayFilters: {
      pageLimit: 25,
      pageNumber: 1,
      pageOption: { value: 25, label: 25 }
    }
  },
  reducers: {
    SetInvoiceState(state, { payload: { field, value } }) {
      state[field] = value;
    },
    SetInvoiceStateFilters(state, { payload: { module, field, value } }) {
      state[module][field] = value;
    }
  },
  extraReducers: {
    [GetInvoiceSummary.pending]: (state, action) => ({
      ...state,
      loading: true,
      success: false
    }),
    [GetInvoiceSummary.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      // message: action.payload.message,
      invoicesSummary: action.payload.invoicesSummary,
      total: action.payload.total
    }),
    [GetInvoiceSummary.rejected]: (state, action) => ({
      ...state,
      invoicesSummary: [],
      invoiceDetails: [],
      caseStatDetails: [],
      total: 0,
      invoiceDetailsTotal: 0,
      caseStatDetailsTotal: 0,
      headerDetailOfInvoiceDetail: {},
      totalReimbursments: {},
      success: false,
      loading: false,
      error: action.payload.err.message
    }),
    [GetInvoiceDetails.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [GetInvoiceDetails.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      // message: action.payload.message,
      invoiceDetails: action.payload.invoiceDetails,
      headerDetailOfInvoiceDetail: action.payload.headerDetail,
      invoiceDetailsTotal: action.payload.total
    }),
    [GetInvoiceDetails.rejected]: (state, action) => ({
      ...state,
      invoiceDetails: [],
      caseStatDetails: [],
      invoiceDetailsTotal: 0,
      caseStatDetailsTotal: 0,
      headerDetailOfInvoiceDetail: {},
      totalReimbursments: {},
      success: false,
      loading: false,
      error: action.payload.err.message
    }),
    [GetCaseStatDetail.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [GetCaseStatDetail.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      caseStatDetails: action.payload.caseStatDetails,
      caseStatDetailsTotal: action.payload.total
    }),
    [GetCaseStatDetail.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false,
      error: action.payload.err.message,
      caseStatDetails: [],
      caseStatDetailsTotal: 0,
    }),
    [DownloadInvoicePdf.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [DownloadInvoicePdf.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      loading: false
    }),
    [DownloadInvoicePdf.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false
    }),
    [PayInvoice.pending]: (state, action) => ({
      ...state,
      loading: true,
      success: false
    }),
    [PayInvoice.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      message: action.payload.message
    }),
    [PayInvoice.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false
    })
  }
});

const { reducer, actions } = invoice;

export const { SetInvoiceState, SetInvoiceStateFilters } = actions;

export default reducer;
